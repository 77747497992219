/**
 * 2024-05-27
 * was intent to print shipment for subcontract
 * 
 * change way to use in 1.3 instead
 */
/**
 * Any change on this file dont forget to change in printShipmentSubcontract.js in serverside
 */



import { jsPDF } from "jspdf";
import "resource/font/THSarabun-normal"
import "resource/font/THSarabun-bold"
import dayjs from "dayjs";
import companyLogo from "branch/resource/company-logo.png"
import { numberFormat, numberFormatNoFlotingPoint } from "utils";

export const printShipment = ({ shData, msData, isPrintJobOrders = true }) => {
  const { CompanyData } = msData.serverData
  const pageWidth = 8.3
  const pageHeight = 11.7
  const pageSize = [pageWidth, pageHeight];
  const defaultHeaderSize = 20;
  const comapanyAddrSize = 10;
  const contentSize = 16;
  const startY = 0.4
  const startX = 0.5
  const lineHeight = 0.30
  const lineHeightHeader = 0.25
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: pageSize
  });

  let numJobOrder = 0
  let pageNo = 0
  let maxJobOrderFirst = 19
  const maxJobOrder = 36
  let startJobOrder = 0
  do {
    pageNo++
    if (pageNo !== 1) {
      doc.addPage(pageSize, "portrait")
    }
    let yPos = startY
    let xPos = startX
    const img = new Image()
    //TODO:: finally change to /file/etc/company_log.png
    img.src = companyLogo

    const logoWidth = 0.85
    const logoHeight = 0.85
    try {
      doc.addImage(img, 'png', xPos, yPos, logoWidth, logoHeight)
      xPos += logoWidth + 0.2
    } catch (e) {
      console.log("error::", e)
    }
    doc.setFont("THSarabun", "bold")
    doc.setFontSize(defaultHeaderSize)
    yPos += 0.2
    doc.text(CompanyData.NameEN, xPos, yPos)
    doc.setFont("THSarabun", "normal")
    doc.setFontSize(comapanyAddrSize)
    yPos += lineHeightHeader
    doc.text(CompanyData.AddrEN, xPos, yPos)
    yPos += lineHeightHeader
    doc.text(`Tax ID: ${CompanyData.TaxId}`, xPos, yPos)
    yPos += lineHeightHeader
    doc.text(`Tel.: ${CompanyData.Tel}${CompanyData.Fax && ` Fax: ${CompanyData.Fax}`}`, xPos, yPos)

    yPos += lineHeight

    doc.setFont("THSarabun", "bold")
    doc.setFontSize(contentSize + 2)
    let topRightYPos = startY + 0.2
    doc.text("ใบแจ้งลาก", 6.7, topRightYPos, { align: "center" })
    topRightYPos += lineHeight
    doc.setFontSize(contentSize)
    doc.setFont("THSarabun", "normal")

    doc.text(`ประเภทงาน: ${shData.ShpmTypNm}`, 6.7, topRightYPos, { align: "center" })
    topRightYPos += lineHeight - 0.05
    const minAptTm = shData.MinAptTm ? dayjs(shData.MinAptTm).startOf('day') : null
    // const maxAptTm = shData.MinAptTm ? dayjs(shData.MaxAptTm).startOf('day') : null
    // const aptTmTxt = minAptTm ? minAptTm.isSame(maxAptTm) ? minAptTm.format("DD/MM/YYYY") : `${minAptTm.format("DD/MM/YYYY")} - ${maxAptTm.format("DD/MM/YYYY")}` : "-"
    doc.text(`วันที่วิ่งงาน:  ${minAptTm ? minAptTm.format("DD/MM/YYYY") : "-"}`, 6, topRightYPos)

    const aptTms = []
    for (const jo of shData.JobOrders) {
      if (jo.AptTm) {
        const curTm = dayjs(jo.AptTm)
        const found = aptTms.find(aptTm => aptTm.tm.isSame(curTm))
        if (found) {
          found.num++
        } else {
          aptTms.push({ tm: curTm, num: 1 })
        }
      }
    }
    //------------------------------ Convert shData ------------------------------
    const dataTable = [
      ...(isPrintJobOrders ? [{ isSecondPage: 1, header: "CUSTOMER :", value: shData.CusNm }] : []),
      { isSecondPage: 1, header: "BOOKING NO :", value: shData.Bkg },
      { isSecondPage: 1, header: "JOB NO :", value: shData.JobNo },
      { isSecondPage: 0, header: "AGENT :", value: shData.Agnt },
      { isSecondPage: 0, header: "VESSEL :", value: `${shData.Vssl} V.${shData.Voy}` },
      { isSecondPage: 0, header: "PORT :", value: shData.Port },
      { isSecondPage: 0, header: "COMMODITY :", value: shData.CmdyTyp },
      { isSecondPage: 0, header: "LOCATION :", value: shData.Loc },
      { isSecondPage: 0, header: "CY :", value: `${shData.TakePlc || "-"} ${shData.TakeDteSt ? ` - First Pick up: ${dayjs(shData.TakeDteSt).format("DD/MM/YYYY HH:mm")}` : ""}` },
      { isSecondPage: 0, header: "RETURN :", value: `${shData.RtnPlc || "-"} ${shData.RtnDteSt ? ` - First Return: ${dayjs(shData.RtnDteSt).format("DD/MM/YYYY HH:mm")}` : ""}` },
      { isSecondPage: 0, header: "CLOSING TIME :", value: shData.RtnDteEn ? dayjs(shData.RtnDteEn).format("DD/MM/YYYY HH:mm") : "-" },
      ...(isPrintJobOrders ?
        [{ isSecondPage: 0, header: "PRICE :", value: shData.UntPrStr ? shData.UntPrStr : shData.UntPr ? numberFormatNoFlotingPoint(+shData.UntPr) : "-" }]
        : []),
      { isSecondPage: 1, header: "VOLUME :", value: shData.ContSizes },
      { isSecondPage: 0, header: "APPOINTMENT TIME :", value: aptTms.map(aptTm => `${aptTm.tm.format("DD/MM/YYYY HH:mm")}x${aptTm.num}`).join(", ") },
      { isSecondPage: 0, header: "COMMENT :", value: (isPrintJobOrders ? shData.Rmk : shData.Message) || "-" },
    ]
    // if(shData.Rcpt){
    //   dataTable.push({ isSecondPage:0, header: "ที่อยู่ออกใบเสร็จ :", value:shData.Rcpt })
    // }
    doc.setLineWidth(0.01)
    const startYData = yPos - 0.1
    const headerWidth = 2.1
    const detailWidth = 4.3
    const headerPos = startX + 0.5
    const detailPos = headerPos + headerWidth + 0.1
    doc.setFont("THSarabun", "bold")
    yPos = startYData + lineHeight * 2 / 3
    let yPosLine = startYData
    let numLine = 0
    let totalMultiLine = 0;
    for (const data of dataTable) {
      if (pageNo !== 1 && data.isSecondPage === 0) continue
      // numLine++
      // console.log("data::", data)
      if (numLine !== 0) {
        doc.line(headerPos, yPosLine, headerPos + headerWidth + detailWidth, yPosLine)
      }

      doc.text(data.header, headerPos + 0.2, yPos)
      const lines = doc.splitTextToSize(data.value, detailWidth - 0.3)
      let multiLine = 0
      for (const line of lines) {
        numLine++
        multiLine++
        doc.text(line, detailPos + 0.2, yPos)
        yPos += lineHeight
        yPosLine += lineHeight
        if (multiLine > 1) {
          totalMultiLine++
        }
      }
      // doc.text(data.value || "-", detailPos + 0.2, yPos)
      // yPos += lineHeight
      // yPosLine += lineHeight
    }
    let multiLine = 0
    if (shData.Rcpt && pageNo === 1) {
      const rcpts = shData.Rcpt.split("\n")
      doc.line(headerPos, yPosLine, headerPos + headerWidth + detailWidth, yPosLine)
      doc.text("ที่อยู่ออกใบเสร็จ :", headerPos + 0.2, yPos)
      for (const rcpt of rcpts) {
        const rcptLines = doc.splitTextToSize(rcpt, detailWidth - 0.3)
        for (const line of rcptLines) {
          numLine++
          multiLine++
          doc.text(line, detailPos + 0.2, yPos)
          yPos += lineHeight
          yPosLine += lineHeight
          if (multiLine > 1) {
            totalMultiLine++
          }
        }
      }
      if (rcpts.length === 0) {
        numLine++
        yPos += lineHeight
        yPosLine += lineHeight
      }
    }
    maxJobOrderFirst = maxJobOrderFirst - (totalMultiLine * 1.3)
    const maxPage = Math.ceil((shData.JobOrders.length - maxJobOrderFirst) / maxJobOrder) + 1
    if (shData.JobOrders.length > maxJobOrderFirst) {
      doc.text(`หน้าที่ ${pageNo}/${maxPage}`, 6.7, topRightYPos + lineHeight - 0.05, { align: "center" })
    }
    doc.rect(headerPos, startYData, headerWidth + detailWidth, numLine * lineHeight, "S")
    doc.line(headerPos + headerWidth, startYData, headerPos + headerWidth, startYData + (numLine * lineHeight))

    const noCol = headerPos - 0.5
    const jobOrdIdCol = noCol + 0.3
    const aptTmCol = jobOrdIdCol + 0.6
    const contSizeCol = aptTmCol + 0.9
    const contNoCol = contSizeCol + 0.5
    const contNoWidth = 1.8
    const contNoSize = contentSize - 1

    const lineHeightJobNo = 0.23

    doc.setFontSize(contNoSize)
    doc.setFont("THSarabun", "normal")


    const loopLength = startJobOrder + Math.min(pageNo === 1 ? maxJobOrderFirst : maxJobOrder, shData.JobOrders.length - startJobOrder)

    if (!isPrintJobOrders) {
      const nameCol = contNoCol + contNoWidth + 0.1
      const namewidth = 0.9
      const licNmCol = nameCol + namewidth + 0.1
      const licNmWidth = 0.9
      const telCol = licNmCol + licNmWidth + 0.1
      const telWidth = 0.9
      // const citzIdCol = telCol + telWidth + 0.2
      // const citzIdWidth = 1

      doc.text("หมายเลขตู้", contNoCol + contNoWidth / 2, yPos + 0.05, { align: "center" })
      doc.text("ชื่อ", nameCol + namewidth / 2, yPos + 0.05, { align: "center" })
      doc.text("ทะเบียน", licNmCol + licNmWidth / 2, yPos + 0.05, { align: "center" })
      doc.text("เบอร์โทร", telCol + telWidth / 2, yPos + 0.05, { align: "center" })
      // doc.text("เลขบัตรปชช.", citzIdCol + citzIdWidth / 2, yPos + 0.05, { align: "center" })
      yPos += lineHeight
      for (let i = startJobOrder; i < loopLength; i++) {
        yPosLine = yPos + 0.05
        numJobOrder++
        let jo = shData.JobOrders[i]
        console.log("jo::", jo)
        doc.text(`${numJobOrder}.`, noCol, yPos)
        doc.text(`${jo.JobOrdId}`, jobOrdIdCol, yPos)
        doc.text(jo.AptTm ? dayjs(jo.AptTm).format("DD/MM HH:mm") : "-", aptTmCol, yPos)
        doc.text(jo.ContSizeFull, contSizeCol, yPos)
        if (jo.ContNo) {
          doc.text(jo.ContNo, contNoCol, yPos)
        } else {
          doc.line(contNoCol, yPosLine, contNoCol + contNoWidth, yPosLine)
        }
        doc.line(nameCol, yPosLine, nameCol + namewidth, yPosLine)
        doc.line(licNmCol, yPosLine, licNmCol + licNmWidth, yPosLine)
        doc.line(telCol, yPosLine, telCol + telWidth, yPosLine)
        // doc.line(citzIdCol, yPosLine, citzIdCol + citzIdWidth, yPosLine)

        yPos += lineHeightJobNo
      }
    } else {
      doc.setFont("THSarabun", "bold")

      yPos += lineHeight / 2
      doc.text("ค่าใช้จ่ายดำเนินงาน", headerPos, yPos)
      doc.setFontSize(contNoSize)
      doc.setFont("THSarabun", "normal")
      const noCol = headerPos + 0.3
      const nameCol = noCol + 0.3
      const amntCol = nameCol + 2
      const qtyCol = amntCol + 1.5
      const totalCol = qtyCol + 0.5

      doc.text("ราคา", amntCol + 0.7, yPos, { align: "right" })
      doc.text(`จำนวน`, qtyCol - 0.15 , yPos)
      doc.text("รวม", totalCol + 0.7, yPos, { align: "right" })
      yPos += lineHeight
      let no = 0
      for (const exp of shData.PCItems) {
        no++
        doc.text(`${no}.`, noCol, yPos)
        doc.text( `(${exp.IsCost?"ต้นทุน":"รายได้"})  ${exp.ExpNm}`, nameCol, yPos)
        doc.text(numberFormat(exp.Amnt), amntCol + 1, yPos, { align: "right" })
        doc.text(`${exp.Qty}`, qtyCol, yPos)
        doc.text(numberFormat(exp.Total), totalCol + 1, yPos, { align: "right" })
        yPos += lineHeight - 0.04
      }
      // const depotCol = contNoCol + contNoWidth + 0.2
      // const depotCol2 = depotCol + 0.5
      // const gateCol = depotCol2 + 0.5
      // const shoreCol = gateCol + 0.5
      // const otherCol = shoreCol + 0.5
      // const checkboxWidth = 0.12
      // const otherColWidth = 1

      // doc.text("ยกตู้", depotCol + checkboxWidth / 2, yPos + 0.05, { align: "center" })
      // doc.text("ผ่านลาน", depotCol2 + checkboxWidth / 2, yPos + 0.05, { align: "center" })
      // doc.text("ผ่านท่า", gateCol + checkboxWidth / 2, yPos + 0.05, { align: "center" })
      // doc.text("ค่าชอร์", shoreCol + checkboxWidth / 2, yPos + 0.05, { align: "center" })
      // doc.text("อื่นๆ", otherCol + otherColWidth / 2, yPos + 0.05, { align: "center" })
      // yPos += lineHeight

      // for (let i = startJobOrder; i < loopLength; i++) {
      //   numJobOrder++
      //   let jo = shData.JobOrders[i]
      //   console.log("jo::", jo)
      //   doc.text(`${numJobOrder}.`, noCol, yPos)
      //   doc.text(`${jo.JobOrdId}`, jobOrdIdCol, yPos)
      //   doc.text(jo.AptTm ? dayjs(jo.AptTm).format("DD/MM HH:mm") : "-", aptTmCol, yPos)
      //   doc.text(jo.ContSizeFull, contSizeCol, yPos)
      //   if (jo.ContNo) {
      //     doc.text(jo.ContNo, contNoCol, yPos)
      //   } else {
      //     doc.line(contNoCol, yPos + 0.05, contNoCol + contNoWidth, yPos + 0.05)
      //   }
      //   doc.rect(depotCol, yPos - 0.1, checkboxWidth, checkboxWidth)
      //   doc.rect(depotCol2, yPos - 0.1, checkboxWidth, checkboxWidth)
      //   doc.rect(gateCol, yPos - 0.1, checkboxWidth, checkboxWidth)
      //   doc.rect(shoreCol, yPos - 0.1, checkboxWidth, checkboxWidth)
      //   doc.line(otherCol, yPos, otherCol + otherColWidth, yPos)
      //   yPos += lineHeightJobNo
      // }
    }
    startJobOrder = numJobOrder
  } while ((numJobOrder < shData.JobOrders.length) && !isPrintJobOrders)
  doc.output('dataurlnewwindow');
}