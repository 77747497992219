
import { Box, Button } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect } from 'react';
import { getSumColorClassName, sxSumColorClass } from 'utils/highlightSumColor';
import { columns } from './columns';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { addIdForDataGrid } from 'utils';
import { branchApiNm } from 'branch/constant/branchApiNm';
import { jobOrderExpenseColumns } from './columns';
import PettyCashDialog from 'pages/Menu1/PettyCash/PettyCashDialog';
import { green, red } from '@mui/material/colors';
import ComboBox from 'components/ComboBox';

let selectedId = null;
let lastFilter = null

const JObOrderExpense = () => {
  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getJobOrderExpense, filter).then(value => {
      if (value.data) {
        // setDataTable(addIdForDataGrid(value.data, 'PCTrnsId'))
        lastFilter = filter

        const newData = []
        let no = 0;
        for (const data of value.data) {
          no++;
          const result = {
            id: data.PCTrnsId,
            No: no,
            AccNm: data.AccNm,
            Amnt: data.Amnt,
            AddAmnt: data.ChdTrns?.reduce((acc, cur) => acc + +cur.Amnt, 0),
            PCTrnsId: data.PCTrnsId,
            AptTm: data.AptTm,
            Loc: data.Loc,
            JobOrdId: data.JobOrdId
          }
          let othAmnt = 0;
          let othDscp = new Set()
          let expTotal = 0;
          if (data.Exps) {
            for (const exp of data.Exps) {
              expTotal += +exp.Amnt
              if ([83, 3, 5, 4, 84, 95, 82, 86, 107, 89, 91, 96, 93, 94, 92, 100].includes(exp.ExpId)) {
                result[`E${exp.ExpId}`] = +exp.Amnt
              } else {
                othAmnt += +exp.Amnt
                othDscp.add(exp.ExpNm)
              }
            }
            if (othAmnt) {
              // console.log("othDscp::", othDscp)  
              result.OthAmnt = othAmnt
              result.OthDscp = [...othDscp].join(', ')
            }
          }
          result.ExpTotal = expTotal
          newData.push(result)
        }
        setDataTable(newData)
      }
    })
  }, [ax])

  const handleCellClick = useCallback((params) => {
    if (params.field === 'ShowDetail') {
      selectedId = params.id
      setDialogOpen(true)
    }
  }, [])

  const handleRowDoubleClick = useCallback((params) => {
    selectedId = params.id
    setDialogOpen(true)
  }, [])

  const onFinish = useCallback((data) => {
    getData(lastFilter)
  }, [getData])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height='100%'>
      <BoxFR>
        <Box flex={1} />
        <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label={"พนักงานขับรถ"}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
        />
        <DateTimeSelect sx={{ width: 180 }} label='วันนัด' start={filterData.AptTmSt} end={filterData.AptTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, AptTmSt: st, AptTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        
        <SearchButton onClick={(() => getData(filterData))} />
        <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
      </BoxFR>
      <Box height='100%' sx={{
        "& .bg-red": { bgcolor: red[100] },
        "& .bg-green": { bgcolor: green[100] }
      }}>
        <DataGridCellExpand
          hideFooter
          rows={dataTable}
          columns={jobOrderExpenseColumns}
          onRowDoubleClick={handleRowDoubleClick}
          onCellClick={handleCellClick}
          initialState={{
            pinnedColumns: {
              left: ['ShowDetail', "No", "AccNm", "AptTm", "JobOrdId", "Loc", "Amnt", "AddAmnt", "TotalOut", "ExpTotal", "Total"],
            },
          }}
        />
      </Box>
      <PettyCashDialog
        selectedId={selectedId}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        onFinish={onFinish} />
    </BoxFC>
  );
}

export default JObOrderExpense