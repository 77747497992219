import dayjs from "dayjs"
import { jsPDF } from "jspdf";

// import { numberFormat } from "utils";
import { branchNoText } from "utils/branchNoText";
//{jobOrderData, shipmentData, jobOrdIds, fName, msData, isPrintDateTime}


export const printJobOrder = ({ printDataArr, msData }) => {

  const headerText = {
    Bkg: "เบอร์บุค",
    Agnt: "เอเย่นต์เรือ",
    Temp: "อุณหภูมิ",
    ContSizeFull: "ขนาดตู้",
    ContNo: "เบอร์ตู้",
    SealNo: "เบอร์ซีล",
    TakeDte: "วันที่รับตู้",
    TakePlc: "ลานรับตู้",
    RtnDte: "วันที่คืนตู้",
    RtnPlc: "ลานคืนตู้",
    LicNm: "ทะเบียนรถ",
    POD: "เมืองปลายทาง",
    TempRtn: "อุณหภูมิก่อนคืนตู้",
    RtnTm: "เวลาคืนตู้",
    Loc: "สถานที่บรรจุ/โกดัง",
    CmdyTyp: "ชื่อสินค้า",
    MileSt: "เลขไมล์รับตู้",
    MileEn: "เลขไมล์คืนตู้",
    MileGenSt: "เลขชั่วโมงเครื่องปั่นไปเริ่มงาน",
    MileGenEn: "เลขชั่วโมงเครื่องปั่นไปจบงาน",
  }

  console.log("printDataArr::", printDataArr)
  const companyData = msData.serverData.CompanyData

  const pageSize = [8.3, 11.7]; //A4
  const midPage = pageSize[0] / 2
  const lineEndMid = midPage - 0.2
  const defaultHeaderSize = 20;
  const contentHeaderSize = 13;
  const contentSize = 15;

  const startY = 0.5
  const startX = 0.3
  const endX = 8
  const lineHeight = 0.25
  const lineHeightContent = 0.3

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: pageSize
  });
  doc.setFont("THSarabun")

  const drawTextWithDot = (text, xPos, yPos, endXPos) => {
    doc.text(text, xPos, yPos)
    doc.line(xPos + doc.getTextWidth(text), yPos + 0.03, endXPos, yPos + 0.03)
  }
  const drawRow = (jobOrderData, name1, name2, yPos) => {
    doc.text(`${headerText[name1]}    ${jobOrderData[name1]|| ""}`, startX, yPos)
    doc.line(startX + doc.getTextWidth(headerText[name1]), yPos +0.03, lineEndMid, yPos+0.03)
    doc.text(`${headerText[name2]}    ${jobOrderData[name2]|| ""}`, midPage, yPos)
    doc.line(midPage + doc.getTextWidth(headerText[name2]), yPos +0.03, endX, yPos+0.03)
  }
  let i = 0;
  for (const { jobOrderData, shipmentData, jobOrdIds, fName } of printDataArr) {
    shipmentData.ShpmTypNm = msData.shipmentTypes.find(st => st.ShpmTypId === shipmentData.ShpmTypId)?.Name || ""
    jobOrderData.TakePlc = msData.poiObj[jobOrderData.TakePOIId] || ""
    jobOrderData.Loc = msData.poiObj[jobOrderData.LocPOIId] || ""
    jobOrderData.RtnPlc = msData.poiObj[jobOrderData.RtnPOIId] || ""
    let j = 0;
    do {
      if (i + j !== 0) {
        doc.addPage(pageSize, "portrait")
      }
      let yPos = startY
      let startCopy = startY
      yPos = startCopy
      
      doc.setFont("THSarabun", "bold")
      doc.setFontSize(defaultHeaderSize)
      doc.text(companyData.NameTH, midPage, yPos, {align: "center"})
      yPos += lineHeight
      doc.setFont("THSarabun", "normal")
      doc.setFontSize(contentSize)
      doc.text(`สำนักงานใหญ่: ${companyData.AddrTH} โทร.${companyData.Tel} แฟกซ์.${companyData.Fax}`, midPage, yPos, {align: "center"})
      yPos += lineHeight
      doc.text(`Tax Id: ${companyData.TaxId}`, midPage, yPos, {align: "center"})
      yPos += lineHeight
      doc.setFont("THSarabun", "bold")
      doc.text(`พนักงานบริการไม่สุภาพแจ้ง 091-9544196 (24 ชั่วโมง)`, midPage, yPos, {align: "center"})
      
      yPos += lineHeight /2
      doc.setLineWidth(0.01)
      doc.line(startX, yPos, endX, yPos)
      yPos += lineHeight *3/2

      doc.text("ใบสั่งปฏิบัติงาน", midPage, yPos, {align: "center"})
      yPos += lineHeight 
      
      
      doc.setFont("THSarabun", "normal")
      doc.text("JOB ORDER", midPage, yPos, {align: "center"})
      doc.text(`เลขที่ ${jobOrdIds[j]?.toString() || ""}`, 6.5, yPos - lineHeight/2)
      yPos += lineHeight 

      const dateX = 6
      doc.text(`วันที่    ${jobOrderData.AptTm?dayjs(jobOrderData.AptTm).format("DD/MM/YYYY HH:mm"):""}`, 6, yPos)
      doc.setLineDashPattern([0.02,0.02],0)
      doc.line(dateX + doc.getTextWidth("วันที่"), yPos +0.05, endX, yPos+0.05)
      yPos += lineHeightContent

      drawRow(jobOrderData, "Bkg", "Agnt", yPos)
      yPos += lineHeightContent
      drawRow(jobOrderData, "Temp", "ContSizeFull", yPos)
      yPos += lineHeightContent
      drawRow(jobOrderData, "ContNo", "SealNo", yPos)
      yPos += lineHeightContent
      drawRow(jobOrderData, "TakeDte", "TakePlc", yPos)
      yPos += lineHeightContent
      drawRow(jobOrderData, "RtnDte", "RtnPlc", yPos)
      yPos += lineHeightContent
      const dateXPos = midPage - 1
      const timeXPos = midPage + 1.5
      drawTextWithDot("อุณหภูมิจากโรงงาน", startX, yPos, dateXPos -0.2 )
      drawTextWithDot("วันที่", dateXPos, yPos, timeXPos -0.2 )
      drawTextWithDot("เวลา", timeXPos, yPos, endX  )
      yPos += lineHeightContent
      drawRow(jobOrderData, "LicNm", "RtnTm", yPos)
      yPos += lineHeightContent
      drawRow(jobOrderData, "Loc", "CmdyTyp", yPos)
      yPos += lineHeightContent
      drawRow(jobOrderData, "MileSt", "MileEn", yPos)
      yPos += lineHeightContent
      drawRow(jobOrderData, "MileGenSt", "MileGenEn", yPos)
      yPos += lineHeightContent
      doc.text("เวลาปั่นไฟ", startX, yPos)
      const startTmXPos = startX + 1.5
      drawTextWithDot("เริ่มเวลา", startTmXPos, yPos, midPage - 0.2)
      drawTextWithDot("วันที่", midPage, yPos, endX)
      yPos += lineHeightContent
      drawTextWithDot("จบเวลา", startTmXPos, yPos, midPage - 0.2)
      drawTextWithDot("วันที่", midPage, yPos, endX)
      yPos += lineHeightContent *2
      
      const colWidth = 2
      doc.line(startX, yPos, startX + colWidth, yPos)
      doc.line(midPage - colWidth/2, yPos, midPage + colWidth/2, yPos)
      doc.line(endX - colWidth, yPos, endX, yPos)
      yPos += lineHeight
      const drvNm = jobOrderData.DrvId ? ` (${msData.driverObj[jobOrderData.DrvId].FName})` : ""
      doc.text("ผู้ว่าจ้าง / ตัวแทนผู้ว่างจ้าง", startX + colWidth/2, yPos, {align: "center"})
      doc.text(`พนักงานขับรถ${drvNm}`, midPage, yPos, {align: "center"})
      doc.text("ผู้สั่งงาน", endX - colWidth/2, yPos, {align: "center"})
      doc.text(fName, endX - colWidth/2, yPos - lineHeight -0.05, {align: "center"})
      yPos += lineHeightContent
      drawTextWithDot("วันที่", startX, yPos, startX + colWidth)
      drawTextWithDot("วันที่", midPage - colWidth/2, yPos, midPage + colWidth/2)
      drawTextWithDot("วันที่", endX - colWidth, yPos, endX)
      yPos += lineHeightContent

      yPos += lineHeightContent
      
      doc.setLineDashPattern([],0)
      const rcptBoxWidth = pageSize[0] -0.6
      doc.lines([[rcptBoxWidth , 0], [0, lineHeightContent * 4], [rcptBoxWidth * -1, 0], [0, lineHeightContent * 3 * -1]], startX, yPos - 0.2, [1, 1], 'S', true)
      const colHeader1 = startX + 0.2
      const colText1 = colHeader1 + 1
      doc.setFont("THSarabun", "bold")
      doc.text("ข้อมูลใบเสร็จ", colHeader1, yPos)
      yPos += lineHeight
      doc.setFont("THSarabun", "bold")
      doc.setFontSize(contentHeaderSize)
      doc.text("TAX ID : ", colHeader1, yPos)
      doc.setFont("THSarabun", "normal")
      doc.setFontSize(contentSize)
      doc.text(`${shipmentData.Job.RcptTaxId} ${branchNoText(shipmentData.Job.RcptNm, shipmentData.Job.RcptBrchNo)}`, colText1, yPos)
      yPos += lineHeight
      doc.setFont("THSarabun", "bold")
      doc.setFontSize(contentHeaderSize)
      doc.text("ชื่อใบเสร็จ : ", colHeader1, yPos)
      doc.setFont("THSarabun", "normal")
      doc.setFontSize(contentSize)
      doc.text(shipmentData.Job.RcptNm, colText1, yPos)
      yPos += lineHeight
      doc.setFont("THSarabun", "bold")
      doc.setFontSize(contentHeaderSize)
      doc.text("ที่อยู่: ", colHeader1, yPos)
      doc.setFont("THSarabun", "normal")
      doc.setFontSize(contentSize - 2)
      const addrs = doc.splitTextToSize(shipmentData.Job.RcptAddr, 6.8)
      doc.text(addrs.join("\n"), colText1 - 0.3, yPos)

      yPos += lineHeight * 2
      doc.setFont("THSarabun", "bold")
      doc.setFontSize(contentSize -2)
      doc.text("1. สินค้าทุเรียนใช้ตุ้อายุไม่เกิน 5 ปี", startX, yPos)
      j++
      i++
    } while (j < jobOrdIds.length)
  }




  doc.output('dataurlnewwindow');
  // window.open(doc.autoPrint() , '_blank')
  // doc.autoPrint({variant: 'non-conform'});
  // window.open(doc.output('bloburl'), '_blank')
}